<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.rescuerQualificationManagement')"
    id="rescuerQualificationManagement"
  >
    <div id="outer-title">{{ $t("i18n.rescuerQualificationManagement") }}</div>
    <jl-table
      @init="init"
      :tableData="tableData"
      :options="options"
      :columns="columns"
      :operates="operates"
      :total="total"
      :height="$defined.HEIGHT - 360 + 'px'"
    >
    </jl-table>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "Qualification",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const state = reactive({
      tableData: [],
      total: 0,
      options: {
        // table 的参数
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "userName",
          label: "staffAccount",
          align: "center",
          search: true,
          type: "input",
          formatter: (row) => {
            return `<a>${row.userName}</a>`;
          },
          method: (row) => {
            router.push({
              path: "/system/qualificationcou",
              query: { id: row.id },
            });
          },
        },
        {
          prop: "nickName",
          label: "staffName",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "telephone",
          label: "mobilePhone",
          align: "center",
        },
        {
          prop: "isAuditStr",
          label: "approvalStatus",
          align: "center",
          search: true,
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "active",
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [],
      },
    });

    const init = async (params) => {
      state.loading = true;
      params.auditStatus = params.isAuditStr || "";
      params.name = params.nickName || "";
      params.number = params.userName || "";
      const { data } = await proxy.$api.system.getRescuerData(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    return {
      ...toRefs(state),
      init,
    };
  },
};
</script>

<style lang="scss"></style>
